import React from "react"
import styled from "styled-components"
import { GlobalStyle, MenuLink, } from "../style/style";
import { Column, Container, FullContaner, Navigation, Row } from '../style/bootstrap';
import SEO from "../components/seo";
import { Gallery } from "gatsby-theme-gallery";
import TopBg from "../images/topBg.jpg"
import MisakMember from "../images/misak.jpg"
import KoubkovaMember from "../images/koubkova.jpg"
import OsickovaMember from "../images/osickova.jpg"

import audio1 from "../audio/Aneta_Koubkova_FirstTime.mp3"
import audio3 from "../audio/Aneta_Koubkova_MyHeartWillGoOn.mp3"
import audio2 from "../audio/Aneta_Koubkova_TogetherAgain.mp3"

import audio4 from "../audio/Karolina_Osickova_AmazingGrace.mp3"
import audio5 from "../audio/Karolína_Osickova_Iwillsurvive.mp3"
import audio6 from "../audio/Karolína_Osickova_Mit_rád .mp3"

import audio7 from "../audio/Milan_Misak_Cuk.mp3"
import audio8 from "../audio/Milan_Misak_Severanka.mp3"
import audio9 from "../audio/Milan_Misak_Tak.mp3"
import audio10 from "../audio/Milan_Misak_To_starnuti.mp3"
//import bgKvetBig from '../images/kvet_cb@2x.png';


const Content = styled.div`
  padding: 0px;
  margin: 0px;
`;

const Top = styled.div`
  padding: 10px;
 height: 60vh;
 background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: 400px ) {
      justify-content: center;
    align-items: start;
    margin: 0;
    padding-top: 10vh;  
    }

`;

const SluzbyItem = styled.div`
  color: white;
  font-weight: bold;
`;

const MainTitle = styled.div`
 display: flex;
    flex-direction: column;
    margin-right: 10vw;

   
  div:nth-child(1) {
    color: #B70071;
  
    font-size: 27px;
  }
  div:nth-child(2) {
    color: white;
    font-weight: bold;
    font-size: 37px;
  }
  div:nth-child(3) {
    color: white;
   
    font-style: italic;
    font-size: 19px;
  }

`;


const MinLogo = styled.div`
 display: flex;
    flex-direction: column;


>div {
  text-align: left;
  div:nth-child(1) {
    color: #B70071;
  
    font-size: 10px;
  }
  div:nth-child(2) {
    color: white;
    font-weight: bold;
    font-size: 13px;
  }
}
   
 

`;


const Title1 = styled.div`
  color: white;
  font-size: 17px;
  text-align: center;
  strong {
    color: #B70071;
  }
`;

const AudioName = styled.div`
  color: white;
  margin-bottom: 10px;
`;

const Kontakt = styled.p`
  color: white;
`;

const Title = styled.p`
  color: white;
  font-size: 22px;
  font-weight: bold;
`;

const Par1 = styled.p`
  color: white;
  margin: 20px;
  text-align: center;
  strong {
    color: #B70071;
  }
`;

const Member = styled.div`
  color: white;
  margin: 20px;
  text-align: center;
  max-width: 700px;
  margin: 30px auto;
  strong {
    color: #B70071;
  }
  display: flex;
  justify-content: center;
    align-items: center;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  img {
    width: 130px;
    z-index: 1;
    border-radius: 10px;
  }
  >div {
    display: flex;
    text-align: left;
    background-color: rgba(0,0,0,0.5);
    padding: 30px 40px;
    flex-direction: column;
    margin-left: ${props => !props.reverse && '-50px'};
    padding-left:  ${props => !props.reverse && '80px'};
    margin-right: ${props => props.reverse && '-50px'};
    padding-right:  ${props => props.reverse && '80px'};
  }
  h5 {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
  }
`;

const IndexPage = () => (
  <Content>

    <GlobalStyle />
    <SEO title='Živá hudba - Missi Band' keywords={[`hudba`, `živá hudba`, `oslavy`]} />

    <Navigation fixed={true}>

      <MinLogo style={{ margin: 0 }}>
        <div>
          <div>živá hudba</div>
          <div>MISSI BAND</div>
        </div>

      </MinLogo>

      <div>
        <ul>

          <MenuLink>
            <a href="#onas">O nás</a>
          </MenuLink>
          <MenuLink>
            <a href="#galerie">Galerie</a>
          </MenuLink>
          <MenuLink>
            <a href="#audio">Audio</a>
          </MenuLink>
          <MenuLink>
            <a href="#kontakt">Kontakt</a>
          </MenuLink>
        </ul>
      </div>
    </Navigation>


    <Top style={{ backgroundImage: `url('${TopBg}')` }}>

      <MainTitle>
        <div>živá hudba</div>
        <div>MISSI BAND</div>
        <div>hudba pro každou událost</div>
      </MainTitle>


    </Top>


    <Container id="onas" full color="#061221">
      <Container>

        <Row style={{ marginTop: -50, paddingBottom: 50 }}>
          <Column size={{ xs: 1, md: 1 / 4 }} center color={"#4B4DD5"} padding={"20px"}><SluzbyItem>Oslava výročí</SluzbyItem></Column>
          <Column size={{ xs: 1, md: 1 / 4 }} center color={"#5139A3"} padding={"20px"}><SluzbyItem>Svatební hostina</SluzbyItem></Column>
          <Column size={{ xs: 1, md: 1 / 4 }} center color={"#533181"} padding={"20px"}><SluzbyItem>Raut</SluzbyItem></Column>
          <Column size={{ xs: 1, md: 1 / 4 }} center color={"#53295F"} padding={"20px"}><SluzbyItem>Podnikový večírek</SluzbyItem></Column>
        </Row>
        <Row>
          <Column>
            <div style={{ maxWidth: 800, margin: '0 auto' }}>
              <Title1>Jsme <strong>muzikanti</strong></Title1>
              <Par1>
                se kterými se můžete potkat na veřejných i soukrumých akcích po celé České republice. Živá hudba má v našich krajích velkou tradici a kdo by si rád nezatancoval nebo třeba nezazpíval.
            </Par1>
              <Par1>V Missi Bandu se zaměřujeme na nejznámější populární písničky a skladby všech hudebních žánrů, ať už českých, nebo zahraničních, aby oslovily co možná nejširší okruh posluchačů. Pokud hledáte živou hudbu na svatební hostinu, oslavu výročí, podnikový večírek nebo jinou kulturní akci, zkuste to s Missi Bandem.</Par1>
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <Member>
              <img alt="Milan Misák" src={MisakMember} />
              <div>
                <h5>Milan Misák</h5>
                <p>Klávesy, kytara,akordeon, zpěv</p>
                <p>Vše začalo v roce 1974, kdy nastupil na hudební školu - obor akordeon.</p>
                <p>Jako samouk se dále vzdělával ve hře na klávesové nástroje a akustickou kytaru.</p>
                <p>V roce 2006 ji uplatnil jako člen skupiny Starý Fóry & Jindra Šťáhlavský (Fešáci).</p>
                <p>Veřejně vystupuje jako muzikant a zpěvák přes 30 let.</p>
              </div>
            </Member>
          </Column>
        </Row>
        <Row>
          <Column>
            <Member reverse>
              <img alt="Karolína Osičková" src={OsickovaMember} />
              <div>
                <h5>Karolína Osičková</h5>
                <p>Zpěv, kytara, ukulele</p>
                <p>Už v útlém věku chodila do dívčího sboru při ZUŠ ve Velkých Pavlovicích.</p>
                <p>Zúčastní se různých folklorních i folkových soutěží, načež a začala se věnovat hře na kytaru. Tu pak vyučovala při SVČ v rodných Velkých Pavlovicích, v současné době poskytuje soukromé lekce. Kytara a zpěv Karolínu přivedly až do republikového finále Dětské Porty, kde získala cenu diváků.</p>
                <p>Od roku 2011 zpívá v big bandu Red Socks Orchestra (dříve JuniorBand Pohořelice).</p>
              </div>
            </Member>
          </Column>
        </Row>
        <Row>
          <Column>
            <Member>
              <img  alt="Aneta Koubková" src={KoubkovaMember} />
              <div>
                <h5>Aneta Koubková</h5>
                <p>Zpěv</p>
                <p>Narodila se do hudební rodiny - tatínek kytarista, maminka zpěvačka. Anetin hudební talent byl nepřehlédnutelný už od malička. Velmi brzy začala zpívat v pěveckém sboru, který ale zanedlouho vystřídaly hodiny sólového zpěvu a hry na klavír.</p>
              </div>
            </Member>
          </Column>
        </Row>
      </Container>
    </Container>
    <FullContaner id="audio" color={'rgba(0, 0, 0, 0.5)'} style={{ marginTop: 50, padding: 50 }}>
      <Container>

      <Row style={{justifyContent: 'center'}}>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Aneta Koubková  - First Time</AudioName>
          <audio controls>
            <source src={audio1} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Aneta Koubková  - Together Again</AudioName>
          <audio controls>
            <source src={audio2} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Aneta Koubková - My Heart Will  Go On</AudioName>
          <audio controls>
            <source src={audio3} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
      </Row>



      <Row style={{justifyContent: 'center',margin:'30 0px'}}>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 4 }}>
          <AudioName>Milan Misák - Cukrářská bosanova</AudioName>
          <audio controls>
            <source src={audio7} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 4 }}>
          <AudioName>Milan Misák - Severanka</AudioName>
          <audio controls>
            <source src={audio8} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 4 }}>
          <AudioName>Milan Misák - Tak nějak málo tančím</AudioName>
          <audio controls>
            <source src={audio9} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 4 }}>
          <AudioName>Milan Misák - To stárnití zrádné</AudioName>
          <audio controls>
            <source src={audio10} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
      </Row>



      <Row style={{justifyContent: 'center'}}>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Karolína Osičková - Amazing Grace</AudioName>
          <audio controls>
            <source src={audio4} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Karolína Osičková - I will survive</AudioName>
          <audio controls>
            <source src={audio5} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
        <Column center padding={'20px'} size={{ xs: 1, md: 1 / 3 }}>
          <AudioName>Karolína Osičková - Mít rád bližního svého</AudioName>
          <audio controls>
            <source src={audio6} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        </Column>
      </Row>




        
      </Container>
    </FullContaner>

    <FullContaner style={{ marginTop: 50, padding: 50 }} color="#061221">
      <Container id="galerie">

        <Gallery />
      </Container>
    </FullContaner>

    <FullContaner style={{ marginTop: 50, padding: 50 }} color={'rgba(0, 0, 0, 0.5)'}>
      <Container id="kontakt" >
        <Row>
          <Column center><Title>Kontakt</Title></Column>
        </Row>
        <Row>
          <Column center><Kontakt>Milan Misák</Kontakt></Column>
          <Column center><Kontakt>+420 608 830 090</Kontakt></Column>
          <Column center><Kontakt>missiband@gmail.com</Kontakt></Column>
          <Column center><Kontakt>Česká Republika / Brno</Kontakt></Column>
        </Row>
      </Container>
    </FullContaner>

  </Content>
)

export default IndexPage
